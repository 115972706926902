<template>
  <div class="index home-index1">
    <div class="content-top">
      <div class="content-top-left">
        <header>
          <div class="title">今日概况</div>
        </header>
        <main>
          <div class="main-item content">
            <div class="main-item-left">
              <div class="title-2">今日获客</div>
              <div class="num-btn">
                <div class="count count1">{{ customCount }}</div>
                <div class="btn btn1" @click="toPush('customerList')">查看</div>
              </div>
            </div>
          </div>
          <div class="main-item">
            <div class="content">
              <div class="main-item-left">
                <div class="title-2">
                  <div class="flex-align">
                    <span>可用余额</span>
                    <span
                      :style="{
                        transform: `rotate(${refresh}deg)`
                      }"
                      class="refresh el-icon-refresh"
                      @click="withdrawSuccess"
                    ></span>
                    <span class="withdrawal" v-if="wallet.freezeAmountYuan"
                      >提现中：{{ wallet.freezeAmountYuan }}元</span
                    >
                    <!-- <span class="warn">?</span> -->
                    <div class="warn-dialog">
                      <div class="title">尊敬的用户:</div>
                      <div class="content">
                        当前显示为账户可用余额 提现中的余额可以点击提现
                        在提现记录中查看您正在提现 中的余额。
                      </div>
                    </div>
                  </div>

                  <div class="main-item-right">
                    <div class="btn1" @click="toWithdraw">提现</div>
                    <div class="line line1"></div>
                    <div class="btn1" @click="toPush('bill')">查看明细</div>
                  </div>
                </div>
                <div class="num-btn" style="min-width: 75%">
                  <div class="count count2" :title="wallet.canUseBalanceYuan">
                    {{ wallet.canUseBalanceYuan }}
                    <span style="font-size: 24px">元</span>
                  </div>
                  <!-- <div class="btn btn2" @click="topUpBalance">充值</div> -->
                </div>
                <!-- <p
                  v-if="Number(wallet.balanceYuan) < 100"
                  title="请尽快充值押金，押金低于100，所有房源可能会下线"
                >
                  请尽快充值押金，押金低于100，所有房源可能会下线
                </p> -->
              </div>
            </div>
          </div>
          <div class="main-item content">
            <div class="main-item-left">
              <div class="title-2">今日带看</div>
              <div class="num-btn">
                <div class="count count3">{{ viewCount }}</div>
                <div class="btn btn3" @click="dialogTableVisible = true">
                  查看
                </div>
              </div>
            </div>
          </div>
          <div class="main-item">
            <div class="content">
              <div class="main-item-left">
                <div class="title-2">
                  <span>我的龙币</span>
                  <div class="main-item-right">
                    <!-- <div class="btn2" @click="goldVisible = true">龙币预警</div>
                    <div class="line line2"></div> -->
                    <div class="btn2" @click="toPush('gold')">查看明细</div>
                  </div>
                </div>
                <div class="num-btn" style="width: 75%">
                  <div class="count count4" :title="coin.balance">
                    {{ coin.balance }}
                    <!-- <span
                      v-if="Number(coin.balance) / 10000 > 1"
                      style="font-size: 24px"
                      >万</span
                    > -->
                  </div>
                  <div class="btn btn4" @click="toPush('recharge')">充值</div>
                </div>

                <p
                  v-if="Number(coin.balance) < 200"
                  title="龙币不足，需尽快充值，数量为0时，客户可能无法与你取得联系"
                >
                  龙币不足，需尽快充值，数量为0时，客户可能无法与你取得联系
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div class="content-top-right">
        <header>
          <div class="title">本月任务</div>
          <div class="handle-calendar">
            <i class="el-icon-arrow-left" @click="last"></i>
            <span>{{ year }}年{{ month > 9 ? month : `0${month}` }}月</span>
            <i class="el-icon-arrow-right" @click="next"></i>
          </div>
        </header>
        <el-calendar v-model="time">
          <template slot="dateCell" slot-scope="{ date, data }">
            <p
              style="text-align: center"
              :class="setCalendar(data) ? 'active' : ''"
              @click="$event => getTake(data, $event)"
            >
              {{ data.day.split('-')[2] }}
            </p>
          </template>
        </el-calendar>
        <!-- 回电弹窗 -->
        <div
          v-if="visible"
          class="code"
          :style="{ top: top + 30 + 'px', left: right - 220 + 'px' }"
        >
          <ul>
            <li v-for="(item, index) in userList" :key="index">
              <span>{{ index + 1 }}</span>
              <div class="name">{{ item.name }}</div>
              <div class="phone">{{ item.phone }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content-bottom">
      <div class="content-bottom-chart">
        <header>
          <div class="title">近7日龙币使用情况</div>
        </header>
        <main id="chart1" :class="!coin7DayData.length ? 'empty' : ''"></main>
      </div>
      <div class="content-bottom-chart">
        <header>
          <div class="title">近7日获客情况</div>
        </header>
        <main id="chart2" :class="!custom7DayData.length ? 'empty' : ''"></main>
      </div>
    </div>
    <!-- 今日带看 -->
    <el-dialog
      title="今日带看"
      :visible.sync="dialogTableVisible"
      width="1000px"
    >
      <div class="body">
        <el-table :data="tableData" border>
          <el-table-column
            type="index"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column prop="name" label="客户姓名"></el-table-column>
          <el-table-column prop="phone" label="电话"> </el-table-column>
          <el-table-column prop="viewTimeFormat" label="带看时间">
          </el-table-column>
          <el-table-column prop="statusName" label="状态">
            <template slot-scope="scope">
              <div class="flex" v-if="scope.row.expires">已过期</div>
              <div class="flex" v-else>
                {{ scope.row.statusName }}
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          v-show="10 < total"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="btn"
          type="primary"
          @click="dialogTableVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 龙币预警 -->
    <el-dialog title="龙币预警" :visible.sync="goldVisible" width="480px">
      <div class="body">
        <p class="gold-p">龙币低于设置的数量时，将会短信通知您！</p>
        <div class="gold">
          <span>设置</span>
          <input type="number" v-model="gold" />
          <span>龙币</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goldVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="setGoldWarn"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 余额充值 -->
    <BalanceDialog
      ref="BalanceDialog"
      :balanceYuan="wallet.canUseBalanceYuan"
    />
    <!-- 提现 -->
    <WithdrawDepositDialog
      ref="WithdrawDepositDialog"
      :balanceYuan="wallet.canUseBalanceYuan"
      @withdrawSuccess="withdrawSuccess"
    />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import BalanceDialog from 'components/BalanceDialog.vue'
import WithdrawDepositDialog from 'components/WithdrawDepositDialog.vue';
import { homePage, takeViewPage, homeTakeView } from 'services/broker'
export default {
  name: 'Index',
  components: {
    BalanceDialog,
    WithdrawDepositDialog
  },
  data () {
    return {
      year: '',
      month: '',
      time: '',
      balance: '1500',
      selectTime: '',
      visible: false,
      top: null,
      right: null,
      balanceYuan: 0,
      gold: 5000,
      goldVisible: false,
      start: "",
      end: '',
      list: ['2023-10-01', '2023-10-09'],
      userList: [
        { name: '张先生', phone: '18864522351' },
        { name: '张先生', phone: '18864522351' },
        { name: '张先生', phone: '18864522351' },
      ],
      tableData: [],
      dialogTableVisible: false,
      page: 1,
      total: 0,
      coin: {},
      coin7DayData: [],
      custom7DayData: [],
      customCount: 0,
      viewCount: 0,
      refresh: 0,
      wallet: {},
      chart1: {
        id: 'chart1',
        xdata: ['09/06', '09/07', '09/08', '09/09', '09/10', '09/11', '09/12'],
        ydata: [820, 932, 901, 934, 1290, 1330, 1320],
        color0: '#5869FC',
        color1: '#FFFFFF',
      },
      chart2: {
        id: 'chart2',
        xdata: ['09/06', '09/07', '09/08', '09/09', '09/10', '09/11', '09/12'],
        ydata: [6, 5, 3, 7, 9, 5, 1],
        color0: '#FFB031',
        color1: '#FFFFFF',
      }
    };
  },
  filters: {
    balanceFilter (val) {
      if (Number(val) / 10000 > 1) {
        return (Number(val) / 10000).toFixed(0)
      } else {
        return val
      }
    }
  },
  watch: {
    month (val) {
      if (val)
        this.getTakeViewParams()
    },
    year (val) {
      if (val)
        this.getTakeViewParams()
    }
  },
  mounted () {
    this.getHomePage()
    this.getNowFormatDate()
    this.getList()
    //  当窗口或者大小发生改变时执行resize，重新绘制图表
    let that = this
    window.addEventListener("resize", function () {
      that.setCategory(that.chart1)
      that.setCategory(that.chart2)
    });
  },
  methods: {
    withdrawSuccess () {
      this.refresh -= 180
      this.getHomePage()
    },
    getHomePage () {
      homePage().then(res => {
        if (res.data.status === 200) {
          let { coin, coin7DayData, custom7DayData, customCount, viewCount, wallet } = res.data.data
          this.coin = coin
          this.coin7DayData = coin7DayData
          this.custom7DayData = custom7DayData
          this.customCount = customCount
          this.viewCount = viewCount
          this.wallet = wallet
          this.chart1.xdata = coin7DayData.map(item => item.day)
          this.chart1.ydata = coin7DayData.map(item => { return Math.abs(item.dataInt) })
          this.chart2.xdata = custom7DayData.map(item => item.day)
          this.chart2.ydata = custom7DayData.map(item => item.data)
          console.log(this.chart1, this.chart2)
          this.setCategory(this.chart1)
          this.setCategory(this.chart2)
        }
      })
    },
    // 每月带看
    getMonthTakeView () {
      let params = {
        start: this.start,
        end: this.end,
        status: 1
      }
      homeTakeView(params).then(res => {
        if (res.data.status === 200) {
          this.list = res.data.data.map(item => `${item.day.substring(0, 4)}-${item.day.substring(4, 6)}-${item.day.substring(6, 8)}`)
        }
      })
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    getList () {
      const now = new Date();
      const y = now.getFullYear();
      const m = ('0' + (now.getMonth() + 1)).slice(-2);
      const d = ('0' + now.getDate()).slice(-2);
      const day = y + m + d
      let params = {
        page: this.page,
        day
      }
      this.Loading = true
      takeViewPage(params).then(res => {
        this.Loading = false
        if (res.data.status === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    // 日历模块
    setCalendar (data) {
      var bool = false
      this.list.forEach(item => {
        if (item === data.day) bool = true
      })
      return bool
    },
    //获取当前日期函数
    getNowFormatDate () {
      let date = new Date()
      this.year = date.getFullYear() //获取完整的年份(4位)
      this.month = date.getMonth() + 1 //获取当前月份(0-11,0代表1月)
      // this.getTakeViewParams()
    },
    last () {
      if (this.month < 2) {
        this.month = 12
        this.year -= 1
      } else {
        this.month -= 1
      }
      this.time = `${this.year}-${this.month}`
      // this.getTakeViewParams()
    },
    next () {
      if (this.month > 11) {
        this.month = 1
        this.year += 1
      } else {
        this.month += 1
      }
      this.time = `${this.year}-${this.month}`
      // this.getTakeViewParams()
    },
    // 计算当前月份多少天
    getMonthDay (year, month) {
      let days = new Date(year, month, 0).getDate();
      console.log(days);
      return days;
    },
    getTakeViewParams () {
      let d = this.getMonthDay(this.year, this.month)
      let end = d > 9 ? d : `0${d}`
      this.start = `${this.year}${this.month > 9 ? this.month : '0' + this.month}01`
      this.end = `${this.year}${this.month > 9 ? this.month : '0' + this.month}${end}`
      this.getMonthTakeView()
    },
    getTake (item) {
      console.log(item);
      this.year = Number(item.day.split('-')[0])
      this.month = Number(item.day.split('-')[1])
      // this.visible = false
      if (this.setCalendar(item)) {
        if (this.selectTime !== item.day) {

          this.$router.push({
            name: 'tapeRecord',
            params: {
              day: item.day
            }
          })
          //     this.visible = true
          //     this.top = $event.clientY
          //     this.right = $event.clientX
          //     this.selectTime = item.day
          //   } else {
          //     this.visible = false
          //     this.selectTime = ''
        }
      }
    },
    // 折线图
    setCategory (obj) {
      var chartDom = document.getElementById(obj.id);
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        grid: {
          top: '5%',
          left: '8%',
          right: '4%',
          bottom: '14%',
          containLabel: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            //坐标轴轴线相关设置
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            // 坐标轴刻度标签的相关设置
            show: true,
            textStyle: {
              color: '#939499', // 字体颜色
              fontSize: 16 // 刻度字体大小
            }
          },
          data: obj.xdata
        },
        yAxis: {
          minInterval: 1,
          axisLabel: {
            // 坐标轴刻度标签的相关设置
            show: true,
            textStyle: {
              color: '#939499', // 字体颜色
              fontSize: 16 // 刻度字体大小
            }
          },
          type: 'value'
        },
        series: [
          {
            data: obj.ydata,
            type: 'line',
            smooth: true,
            showSymbol: true,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: obj.color0 },
                  { offset: 1, color: obj.color1 }
                ])
              }
            },
            itemStyle: {
              normal: {
                color: obj.color0, //改变折线点的颜色
                lineStyle: {
                  color: obj.color0 //改变折线颜色
                }
              }
            },
          }
        ]
      };

      option && myChart.setOption(option);
      myChart.resize();
    },
    // 龙币预警
    setGoldWarn () {
      this.goldVisible = false;
    },
    // 余额充值
    topUpBalance () {
      this.$refs.BalanceDialog.balanceVisible = true
    },
    toWithdraw () {
      this.$refs.WithdrawDepositDialog.balanceVisible = true
    },
    toPush (name) {
      this.$router.push({
        name,
        params: {
          tabIndex: '1'
        }
      })
    },
  }
};
</script>

<style lang="less" scoped>
.index {
  height: 100%;
  background-color: #faf8f5 !important;
  box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04) !important;
  .content-top {
    width: 100%;
    height: 460px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &-left {
      width: 980px;
      height: 100%;
      border-radius: 12px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04) !important;
      margin-right: 20px;
      overflow: hidden;
      main {
        width: 100%;
        height: calc(100% - 70px);
        padding: 24px 40px 36px 36px;
        display: flex;
        flex-wrap: wrap;
        border-radius: 12px;
        .main-item {
          width: calc(50% - 14px);
          height: calc(50% - 14px);
          background-color: red;
          padding: 24px 24px 24px 32px;
          position: relative;
          .content {
            display: flex;
            justify-content: space-between;
          }
          p {
            margin-left: 8px;
            line-height: 20px;
            color: rgba(255, 66, 66, 1);
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &:nth-child(1) {
            margin-right: 28px;
            margin-bottom: 28px;
            background: url(../assets/home-left-1.png);
            background-size: 100% 100%;
          }
          &:nth-child(2) {
            background: url(../assets/home-left-2.png);
            background-size: 100% 100%;
          }
          &:nth-child(3) {
            background: url(../assets/home-left-3.png);
            background-size: 100% 100%;
          }
          &:nth-child(4) {
            margin-left: 28px;
            background: url(../assets/home-left-4.png);
            background-size: 100% 100%;
          }
          &-left {
            width: 100%;
            margin: 4px 8px;
            .title-2 {
              width: 100%;
              font-size: 24px !important;
              font-weight: 400;
              line-height: 38px;
              color: rgba(51, 51, 51, 1);
              display: flex;
              justify-content: space-between;
              span {
                font-size: 24px !important;
                font-weight: 400;
                line-height: 38px;
                color: rgba(51, 51, 51, 1);
              }
              .refresh {
                font-size: 28px !important;
                margin-left: 8px;
                color: rgba(236, 102, 0, 1);
                cursor: pointer;
                transition: all 0.2s linear 0s;
                &.active {
                  transform: rotate(-360deg);
                }
              }
              .withdrawal {
                margin-left: 8px;
                font-size: 14px !important;
                color: rgba(51, 51, 51, 1);
              }

              .warn {
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid rgba(236, 102, 0, 1);
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(236, 102, 0, 1);
                cursor: pointer;
                font-size: 16px !important;
                margin-left: 8px;
              }
              .warn:hover + .warn-dialog {
                display: block;
              }
              .warn-dialog {
                position: absolute;
                display: none;
                top: 65px;
                padding: 16px;
                border-radius: 4px;
                background-color: #fff;
                box-shadow: 0 0 6px 0 rgba(13, 4, 9, 0.2);
                z-index: 100;
                .title {
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 36px;
                  color: rgba(0, 0, 0, 1);
                }
                .content {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 36px;
                  color: rgba(51, 51, 51, 1);
                }
              }
            }
            .num-btn {
              width: 58%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .count {
                font-size: 44px;
                font-weight: 600;
                line-height: 55px;
                width: calc(100% - 90px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.count1 {
                  color: #0074d9;
                }
                &.count2 {
                  color: #ec6600;
                }
                &.count3 {
                  color: #596080;
                }
                &.count4 {
                  color: #404fd6;
                }
              }
              .btn {
                width: 82px;
                height: 36px;
                opacity: 1;
                border-radius: 40px;
                line-height: 36px;
                text-align: center;
                cursor: pointer;
                color: #fff;
                font-size: 16px;
                &.btn1 {
                  background: linear-gradient(
                    180deg,
                    #99e0ff 0%,
                    #299bff 98.62%
                  );
                }
                &.btn2 {
                  background: linear-gradient(180deg, #ffc96b 0%, #ff8629 100%);
                }
                &.btn3 {
                  background: linear-gradient(180deg, #ced2e6 0%, #939bc2 100%);
                }
                &.btn4 {
                  background: linear-gradient(180deg, #ccd1ff 0%, #808cff 100%);
                }
              }
            }
          }
          &-right {
            // width: calc(42% - 16px);
            display: flex;
            justify-content: end;
            align-items: center;
            position: absolute;
            top: 20px;
            right: 24px;
            .btn1 {
              line-height: 22px;
              color: rgba(236, 102, 0, 1);
              cursor: pointer;
              font-size: 14px;
            }
            .line {
              width: 1px;
              height: 15px;
              margin: 0 6px;
              &.line1 {
                background-color: rgba(236, 102, 0, 0.3);
              }
              &.line2 {
                background-color: rgba(64, 79, 214, 0.3);
              }
            }
            .btn2 {
              line-height: 22px;
              color: rgba(64, 79, 214, 1);
              cursor: pointer;
              font-size: 14px;
            }
          }
        }
      }
    }
    &-right {
      width: calc(100% - 1000px);
      min-width: 380px;
      height: 100%;
      border-radius: 12px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04) !important;
      overflow: hidden;
      .active {
        // color: red;
        position: relative;
        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          opacity: 1;
          background: #ec6600;
          border-radius: 50%;
          position: absolute;
          top: 2px;
          right: calc(50% - 12px);
        }
      }

      .code {
        position: absolute;
        top: 30px;
        padding: 10px 20px;
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.12) !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        ul {
          padding: 24px;
          li {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            &:last-child {
              margin-bottom: 0;
            }
            span {
              display: block;
              width: 18px;
              height: 18px;
              text-align: center;
              line-height: 18px;
              border-radius: 50%;
              opacity: 1;
              background: #ffdfc7;
              color: #ec6600;
              font-size: 14px;
            }
            .name {
              width: 100px;
              line-height: 20px;
              color: rgba(51, 51, 51, 1);
              text-align: center;
            }
            .phone {
              width: 100px;
              line-height: 20px;
              color: rgba(51, 51, 51, 1);
            }
          }
        }
      }
    }
  }
  .content-bottom {
    width: 100%;
    height: calc(100% - 480px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-chart {
      width: calc(50% - 10px);
      height: 100%;
      overflow: hidden;
      border-radius: 12px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04) !important;
      main {
        width: 100%;
        height: calc(100% - 70px);
        padding: 24px 36px 36px;
        &.empty {
          background-size: 160px 160px;
        }
      }
    }
  }
  header {
    width: 100%;
    height: 70px;
    padding: 0 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f7f5f2;
    box-sizing: border-box;
    .title {
      font-size: 22px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
    }
    .handle-calendar {
      display: flex;
      align-items: center;
      i {
        cursor: pointer;
      }
      span {
        font-size: 20px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        margin: 0 20px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
// 日历
/deep/ .el-calendar-table .el-calendar-day {
  height: 40px;
}
/deep/ .el-calendar__header {
  display: none;
}
/deep/ .el-calendar-table td {
  border: 0 !important;
}
/deep/ .el-calendar-table thead th:before {
  content: '周';
}
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
}
.body {
  .gold-p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    margin-bottom: 20px;
  }
  .gold {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    input {
      width: 130px;
      height: 50px;
      text-align: center;
      opacity: 1;
      border-radius: 4px;
      background: #fcf4ef;
      margin: 0 16px;
    }
  }
  .form-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(153, 153, 153, 1);
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    input {
      width: 130px;
      height: 40px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(252, 244, 239, 1);
      text-align: center;
    }
    /deep/ .el-radio__label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner {
      border: 3px solid rgba(236, 102, 0, 0.3);
      background: aliceblue;
      &::after {
        width: 8px;
        height: 8px;
        background-color: rgba(236, 102, 0, 1);
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

/deep/ .el-table th.el-table__cell {
  background: #f5f5f5;
  height: 50px;
  line-height: 50px;
  padding: 0;
  border-right: 0;
}
/deep/ .el-table td.el-table__cell {
  height: 50px;
  padding: 0;
  border-right: 0;
  div {
    // height: 50px;
    // display: flex;
    // align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/deep/
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #e6edfa;
}
</style>